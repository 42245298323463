<template>
  <div class="aps-wrap flex-column">

    <div class="aps-head">
      <div class="title1 mb-8">{{$t('payments.payments')}}</div>
      <MiniTabs :tabs="tabs" :tabName="preOpenData.tabName" @openTab="openTab"></MiniTabs>
    </div>

    <StudentTableWithCard
        v-if="currentTab.id === 11"
        :key="11"
        :options="tabs[0]"
        :preOpen="preOpenData"
    ></StudentTableWithCard>

    <StudentTableWithCard
        v-else-if="currentTab.id === 12"
        :key="12"
        :options="tabs[1]"
        :preOpen="preOpenData"
    ></StudentTableWithCard>

  </div>
  <UndoMessage></UndoMessage>

</template>

<script>
import "@/assets/styles/transitions.scss"
import MiniTabs from "@/components/students/card/MiniTabs";
import UndoMessage from "@/components/UndoMessage";
import StudentTableWithCard from "@/components/students/StudentTableWithCard";
// import Referral from "@/components/students/tables/finance/Referral";
import ActiveInvoices from "@/components/students/tables/finance/ActiveInvoices";
import AllInvoices from "@/components/students/tables/finance/AllInvoices";

export default {
  name: "home",
  components: {
    AllInvoices,
    ActiveInvoices,
    // Referral,
    StudentTableWithCard,
    UndoMessage,
    MiniTabs
  },
  data() {
    return {
      tabs: [
        {
          id: 11,
          name: 'active_invoices',
          title: 'Active invoices',
          description: 'Active invoices',
          cardTabs: ['payment_info','payment_history'],
          count: 0,
          service: 'payments',
          params: {
            schema: 'paymentView'
          },
          component: ActiveInvoices
        },
        {
          id: 12,
          name: 'all_invoices',
          title: 'All invoices',
          description: 'All invoices',
          cardTabs: ['payment_info','payment_history'],
          count: 0,
          service: 'payments',
          params: {
            schema: 'paymentView'
          },
          component: AllInvoices
        },
      ],
      currentTab: {},
      preOpenData: {},
    }
  },
  methods: {
    openTab(tab) {
      this.currentTab = tab;
    },
  },
  created() {
    this.preOpenData = {...this.$route.query};
    this.$log('PRE OPEN DATA:', this.preOpenData);
  },
  async mounted() {
    // await this.reloadCounters();
  },
}
</script>

<style scoped lang="scss">

</style>